import React from 'react'

//Fera.bio
const Upload = React.lazy(() => import('./views/upload_dataset/Upload.js'))
const Manage = React.lazy(() => import('./views/manage/Manage.js'))
const Basecall = React.lazy(() => import('src/views/basecall/Basecall.js'))
const CloudTasks = React.lazy(() => import('src/views/cloud_tasks/CloudTasks.js'))
const CloudResults = React.lazy(() => import('src/views/cloud_results/CloudResults.js'))
const VisualizeQC = React.lazy(() => import('src/views/visualize_qc/VisualizeQC.js'))

const Morph = React.lazy(() => import('./views/redirect/homelo'))
const Redirect = React.lazy(() => import('./views/register/Register.js'))
const ViewProfile = React.lazy(() => import('./views/profile/UserProfile.js'))
const AdminPanel = React.lazy(() => import('./views/developer/dev_panel.js'))
const Inspect = React.lazy(() => import('./views/redirect/homelo'))
const Register = React.lazy(() => import('./views/register/Register.js'))
const Invite = React.lazy(() => import('./views/invite/Invite.js'))
const Share = React.lazy(() => import('./views/share/Share.js'))
const BugReport = React.lazy(() => import('./views/bug_report/ReportBug.js'))
const Feedback = React.lazy(() => import('./views/feedback/Feedback.js'))

const UploadReference = React.lazy(() => import('./views/upload_reference/UploadReference'))

const getRoutes = (isAuthenticated) => {
  if (isAuthenticated) {
    return [
      // Define routes for authenticated users
      { path: '/', exact: true, name: 'Home' },
      { path: '/upload/dataset', name: 'Upload Dataset', element: Upload },
      { path: '/upload/references', name: 'Upload References', element: UploadReference },
      { path: '/upload/barcodes', name: 'Upload Barcodes', element: Upload },
      { path: '/manage', name: 'Manage', element: Manage },
      {
        path: '/visualize_qc',
        name: 'VisualizeQC',
        element: VisualizeQC,
      },
      { path: '/analysis/basecall', name: 'Basecall', element: Basecall },
      { path: '/analysis/cloudtasks', name: 'CloudTasks', element: CloudTasks },
      { path: '/analysis/cloudresults', name: 'CloudResults', element: CloudResults },
      { path: '/analysis/morph', name: 'Morph', element: Morph },
      { path: '/profile/viewprofile', name: 'User Profile', element: ViewProfile },
      { path: '/admin_panel', name: 'Panel', element: AdminPanel },
      { path: '/register_invited', name: 'Register', element: Register },
      { path: '/invite', name: 'Invite', element: Invite },
      { path: '/analysis/cloudtasks', name: 'CloudTasks', element: CloudTasks },
      { path: '/analysis/cloudresults', name: 'CloudResults', element: CloudResults },
      { path: '/visualize/inspect', name: 'Inspect', element: Inspect },
      { path: '/report_bug', name: 'BugReport', element: BugReport },
      { path: '/feedback', name: 'Feedback', element: Feedback },
      { path: '/share', name: 'Share', element: Share },
    ]
  } else {
    return [
      // Define routes for unauthenticated users
      { path: '/', exact: true, name: 'Home' },
      { path: '/upload/dataset', name: 'Upload Dataset', element: Redirect },
      { path: '/upload/references', name: 'Upload References', element: Redirect },
      { path: '/upload/barcodes', name: 'Upload Barcodes', element: Redirect },
      { path: '/download', name: 'Download', element: Redirect },
      { path: '/analysis', name: 'Analysis', element: Redirect, exact: true },
      { path: '/analysis/basecall', name: 'Basecall', element: Redirect },
      { path: '/analysis/redirect', name: 'Redirect', element: Redirect },
      { path: '/analysis/discover', name: 'Discover', element: Redirect }, // ... other unauthenticated routes ...
      { path: '/analysis/morph', name: 'Morph', element: Redirect }, // ... other unauthenticated routes ...
      { path: '/profile/viewprofile', name: 'User Profile', element: Redirect }, // ... other unauthenticated routes ...
      { path: '/admin_panel', name: 'Panel', element: Redirect },
      { path: '/analysis/cloudtasks', name: 'CloudTasks', element: Redirect },
      { path: '/analysis/cloudresults', name: 'CloudResults', element: Redirect },
      { path: '/visualize/inspect', name: 'Inspect', element: Redirect },
      { path: '/share', name: 'Share', element: Redirect },
    ]
  }
}

export default getRoutes

import React from 'react'
import { useLocation } from 'react-router-dom'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import RegisterSidebar from 'src/views/register/registerSidebar'
import { CContainer } from '@coreui/react'

const DefaultLayout = () => {
  const location = useLocation()
  const isRegistrationRoute = location.pathname === '/register_invited'

  return (
    <div className="layout-container">
      <div className="sidebar-wrapper">
        {isRegistrationRoute ? <RegisterSidebar /> : <AppSidebar />}
      </div>
      <div className="content-wrapper">
        <AppHeader />
        <div className="wrapper d-flex flex-column flex-grow-1 bg-light">
          <div className="body flex-grow-1 mt-4">
            <CContainer fluid className="px-4">
              <AppContent />
            </CContainer>
          </div>
          <AppFooter />
        </div>
      </div>
    </div>
  )
}

export default DefaultLayout

import React from 'react'
import { useSelector } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react'
import { AppSidebarNav } from '../../components/AppSidebarNav'
import CIcon from '@coreui/icons-react'
import { cilUserFollow, cilAccountLogout } from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'
import xnabrand from 'src/assets/xnabrand/feracloud_logo_text.png'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import { useAuth0 } from '@auth0/auth0-react'

const RegisterSidebar = () => {
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const { logout } = useAuth0() // Get the logout function

  const registerNavItems = [
    {
      component: CNavTitle,
      name: 'Account',
    },
    {
      component: CNavItem,
      name: 'Alpha registration',
      to: '/register_invited',
      icon: <CIcon icon={cilUserFollow} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Log out',
      to: '/',
      icon: <CIcon icon={cilAccountLogout} customClassName="nav-icon" />,
      onClick: () => logout({ logoutParams: { returnTo: window.location.origin + '/' } }),
    },
  ]

  return (
    <CSidebar position="fixed" unfoldable={unfoldable} visible={sidebarShow}>
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img className="sidebar-brand-full" src={xnabrand} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <div className="navbar-container">
          <SimpleBar>
            <AppSidebarNav items={registerNavItems} />
          </SimpleBar>
        </div>
      </CSidebarNav>
      <div className="d-none d-lg-flex" />
    </CSidebar>
  )
}

export default RegisterSidebar

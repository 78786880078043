import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilMemory,
  cil3d,
  cilCloud,
  cilDescription,
  cilDrop,
  cilSearch,
  cilCode,
  cilNotes,
  cilPencil,
  cilChartLine,
  cilPuzzle,
  cilChevronDoubleRight,
  cilArrowThickFromBottom,
  cilArrowThickToBottom,
  cilVector,
  cilApplications,
  cilBarChart,
  cilBank,
  cilCommentSquare,
  cilClearAll,
  cilBarcode,
  cilSpeedometer,
  cilStar,
  cilAccountLogout,
  cilShare,
  cilClipboard,
  cilAsterisk,
  cilBug,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'
import roleAccess from './roleAccess'

const getNavItems = (userRole, logout) => {
  const allNavItems = [
    {
      component: CNavTitle,
      name: 'Experiments',
    },
    {
      component: CNavItem,
      name: 'Manage',
      to: '/manage',
      icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
    },
    {
      component: CNavGroup,
      name: 'Upload',
      icon: <CIcon icon={cilArrowThickFromBottom} customClassName="nav-icon" />,
      items: [
        {
          component: CNavItem,
          name: 'Datasets',
          to: '/upload/dataset',
        },
        {
          component: CNavItem,
          name: 'References',
          to: '/upload/references',
        },
      ],
      className: 'nav-group-no-background',
    },
    {
      component: CNavItem,
      name: 'Share',
      to: '/share',
      icon: <CIcon icon={cilShare} customClassName="nav-icon" />,
    },
    {
      component: CNavTitle,
      name: 'Analyze',
    },
    {
      component: CNavItem,
      name: 'Basecall',
      to: '/analysis/basecall',
      icon: <CIcon icon={cilMemory} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Cloud Tasks',
      to: '/analysis/cloudtasks',
      icon: <CIcon icon={cilCloud} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Results',
      to: '/analysis/cloudresults',
      icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Cluster',
      to: '/analysis/cluster',
      icon: <CIcon icon={cilClearAll} customClassName="nav-icon" />,
      badge: {
        color: 'danger',
        text: 'dev',
      },
    },
    {
      component: CNavItem,
      name: 'Morph',
      to: '/analysis/morph',
      icon: <CIcon icon={cil3d} customClassName="nav-icon" />,
      badge: {
        color: 'danger',
        text: 'dev',
      },
    },
    {
      component: CNavItem,
      name: 'Discover',
      to: '/analysis/morph',
      icon: <CIcon icon={cilVector} customClassName="nav-icon" />,
      badge: {
        color: 'danger',
        text: 'dev',
      },
    },
    {
      component: CNavTitle,
      name: 'Visualize',
    },
    {
      component: CNavItem,
      name: 'Quality Metrics',
      to: '/visualize_qc',
      icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Inspect Reads',
      to: '/visualize/inspect',
      icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
      badge: {
        color: 'danger',
        text: 'dev',
      },
    },
    {
      component: CNavItem,
      name: 'Logos',
      to: '/visualize/logos',
      icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />,
      badge: {
        color: 'danger',
        text: 'dev',
      },
    },
    {
      component: CNavTitle,
      name: 'Developer Tools',
    },
    {
      component: CNavItem,
      name: 'Panel',
      to: '/admin_panel',
      icon: <CIcon icon={cilCode} customClassName="nav-icon" />,
      badge: {
        color: 'dark',
        text: 'admin',
      },
    },
    {
      component: CNavItem,
      name: 'Invite',
      to: '/invite',
      icon: <CIcon icon={cilCode} customClassName="nav-icon" />,
      badge: {
        color: 'dark',
        text: 'admin',
      },
    },
    {
      component: CNavTitle,
      name: 'Tests',
    },
    {
      component: CNavItem,
      name: 'Register',
      to: '/register_invited',
      icon: <CIcon icon={cilCode} customClassName="nav-icon" />,
      badge: {
        color: 'dark',
        text: 'admin',
      },
    },
    {
      component: CNavTitle,
      name: 'Alpha Test',
    },
    {
      component: CNavItem,
      name: 'Report Bug',
      to: '/report_bug',
      icon: <CIcon icon={cilBug} customClassName="nav-icon" />,
      badge: {
        color: 'warning',
        text: 'alpha',
      },
    },
    {
      component: CNavItem,
      name: 'Feedback',
      to: '/feedback',
      icon: <CIcon icon={cilCommentSquare} customClassName="nav-icon" />,
      badge: {
        color: 'warning',
        text: 'alpha',
      },
    },
    {
      component: CNavTitle,
      name: 'Account',
    },
    {
      component: CNavItem,
      name: 'Profile',
      to: '/profile/viewprofile',
      icon: <CIcon icon={cilMemory} customClassName="nav-icon" />,
    },
    {
      component: CNavItem,
      name: 'Settings',
      to: '/profile/settings',
      icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
      badge: {
        color: 'danger',
        text: 'dev',
      },
    },
    {
      component: CNavItem,
      name: 'Billing',
      to: '/profile/billing',
      icon: <CIcon icon={cilBank} customClassName="nav-icon" />,
      badge: {
        color: 'danger',
        text: 'dev',
      },
    },
    {
      component: CNavItem,
      name: 'Log out',
      to: '/',
      icon: <CIcon icon={cilAccountLogout} customClassName="nav-icon" />,
      onClick: (e) => {
        e.preventDefault()
        logout({ logoutParams: { returnTo: window.location.origin + '/' } })
      },
    },
  ]

  const filterItemsByRole = (items, role) => {
    const accessRole = roleAccess.hasOwnProperty(role) ? role : 'default'
    if (roleAccess[accessRole] === 'all') return items
    return items.filter((item) => !roleAccess[accessRole].includes(item.name))
  }
  // Return filtered nav items based on user role
  return filterItemsByRole(allNavItems, userRole || 'default')
}

export default getNavItems

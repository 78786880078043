//Lists navigation items to filter
const roleAccess = {
  admin: 'all',
  academic_alpha: [
    'Panel',
    'Billing',
    'Logos',
    'Inspect Reads',
    'Discover',
    'Morph',
    'Cluster',
    'Developer Tools',
    'Invite',
    'Tests',
    'Register',
    'Settings',
  ],
  user: [
    'Panel',
    'Billing',
    'Logos',
    'Alpha Test',
    'Inspect Reads',
    'Cloud Tasks',
    'Discover',
    'Morph',
    'Cluster',
    'Basecall',
    'Developer Tools',
    'Invite',
    'Tests',
    'Register',
    'Settings',
  ],
  beta: [
    'Panel',
    'Billing',
    'Logos',
    'Inspect Reads',
    'Discover',
    'Morph',
    'Cluster',
    'Report Bug',
    'Developer Tools',
    'Invite',
    'Tests',
    'Register',
    'Settings',
  ],
  free: [
    'Panel',
    'Billing',
    'Logos',
    'Inspect Reads',
    'Quality Metrics',
    'Cloud Tasks',
    'Discover',
    'Alpha Test',
    'Morph',
    'Cluster',
    'Feedback',
    'Report Bug',
    'Visualize',
    'Basecall',
    'Developer Tools',
    'Invite',
    'Tests',
    'Register',
    'Settings',
  ],
  default: [
    'Panel',
    'Billing',
    'Logos',
    'Inspect Reads',
    'Cloud Tasks',
    'Discover',
    'Morph',
    'Alpha Test',
    'Feedback',
    'Report Bug',
    'Cluster',
    'Invite',
    'Modify',
    'Basecall',
    'Developer Tools',
    'Upload',
    'Tests',
    'Register',
    'Settings',
  ],
}

export default roleAccess
